<script>
import ClienteList from "components/clientes/components/cliente/List"
import {list} from "@/domain/gerencia/clientes/services"
import ECol from "components/layout/components/Col"
import ERow from "components/layout/components/Row"
import {date, debounce, UInput, USelect} from "uloc-vue"
import {ErpSField} from 'uloc-vue-plugin-erp'
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
// import StatusSelect from "components/clientes/components/cliente/include/StatusSelect"
import {datePtToEn} from "@/utils/date"
import DateInput from "@/reuse/input/Date"
import StatusSelect from "components/gerencia/cliente/components/include/StatusClienteSelect"

export default {
  name: 'ClientesListPage',
  mixins: [],
  props: {
    fila: {required: false},
    project: {required: false},
    autoload: {type: Boolean, default: true}
  },
  components: {
    ClienteList,
    ECol,
    ERow,
    UInput,
    USelect,
    PersonSelect,
    ErpSField,
    // StatusSelect,
    DateInput,
    StatusSelect
  },
  data() {
    return {
      maisFiltros: false,
      table: {
        serverData: [],
        selected: [],
        loading: false,
        filters: {
          id: null,
          busca: null,
          representante: null,
          status: ['active', 'maintenance', 'suspended', 'homolog'],
          active: null
        },
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 50
        }
      }
    }
  },
  mounted() {
    this.autoload && this.load()
  },
  watch: {
    fila(v) {
      this.table.filters.fila = v
      this.$nextTick(() => {
        this.load()
      })
    },
    'table.filters.internalStatus'(v) {
      this.$nextTick(() => {
        this.load()
      })
    },
    'table.filters.busca': debounce(function () {
      this.load()
    }, 500)
  },
  computed: {},
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }
      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.busca && extraFilters.push(`&search=${String(this.table.filters.busca).replace('#', '_hash-')}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.gerente && extraFilters.push(`&gerente=${this.table.filters.gerente.id}`)
      this.table.filters.status.length && extraFilters.push(`&status=${this.table.filters.status.join(',')}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dominio'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="scliente-search console-cliente-lista">
      <u-btn @click="maisFiltros = !maisFiltros" flat no-caps round :class="{'m-b': maisFiltros}">
        <div v-if="maisFiltros">
          <i class="fal fa-filter font-12 text-positive"></i>
        </div>
        <div v-else><i class="fal fa-filter font-12"></i></div>
      </u-btn>
      <div class="filters e-input-modern" v-if="maisFiltros">
        <e-row mr>
          <e-col style="max-width: 300px">
            <u-input placeholder="Pesquisa inteligente" @keydown.enter="load" v-model="table.filters.busca"
                     hide-underline inverted-light class="no-box-shadow" :after="[{icon: 'search'}]"/>
          </e-col>
          <e-col class="flex justify-between">
            <u-btn @click="load" label="Buscar" flat no-caps class="bg-grey-1"/>
          </e-col>
          <e-col style="min-width: 150px">
              <status-select size="2" label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                             v-model="table.filters.status" multiple/>
          </e-col>
        </e-row>
        <div>
          <e-row mr>
            <e-col>
              <erp-s-field label="Gerente">
                <person-select v-model="table.filters.gerente"/>
              </erp-s-field>
            </e-col>
<!--            <e-col style="max-width: 200px">
              <erp-s-field label="Status">
                <status-select v-model="table.filters.status"/>
              </erp-s-field>
            </e-col>-->
          </e-row>
          <e-row mr>
            <e-col style="min-width: 150px">
              <date-input @keydown.enter.native="load" label="Data (De)" v-model="table.filters.data1"/>
            </e-col>
            <e-col style="min-width: 150px">
              <date-input @keydown.enter.native="load" label="Data (Até)" v-model="table.filters.data2"/>
            </e-col>
            <e-col style="min-width: 150px">
              <erp-s-field
                  label="Filtro Data"
              >
                <u-select
                    hide-underline inverted-light class="no-box-shadow"
                    :options="[
                            {label: 'Contrato', value: 'contrato'},
                            {label: 'Início Produção', value: 'producao'},
                        ]"
                    v-model="table.filters.tipoData"/>
              </erp-s-field>
            </e-col>
          </e-row>
        </div>
      </div>
    </div>
    <cliente-list :data="table.serverData" :project="project" :request="request" :options="table" :option-column="false"/>
  </div>
</template>
