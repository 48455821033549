<template>
  <div class="cliente-list m-t">
    <u-table
        ref="table"
        color="primary"
        :data="data"
        :columns="columns"
        :visible-columns="visibledColumns"
        row-key="id"
        :pagination.sync="options.serverPagination"
        selection="multiple"
        :loading="options.loading"
        :selected.sync="options.selected"
        class="cliente-table"
        :rows-per-page-options="[20, 50, 100]"
        :hide-no-results-label="true"
        @request="request"
    >
      <u-tr slot="header" slot-scope="props">
        <u-th auto-width>
          <u-checkbox
              v-model="props.selected"
              :indeterminate="props.partialSelected"
              size="xs"
          />
        </u-th>
        <u-th v-for="col in props.cols" :key="col.name" :props="props">
          <template v-if="col.name === 'status'">
            {{ col.label }} <i @click.stop.prevent="listOptions" class="m-l fal fa-cog inline text-grey-8"/>
          </template>
          <template v-else>{{col.label}}</template>
        </u-th>
      </u-tr>

      <u-tr slot="body" slot-scope="props"
            :props="props" :class="[{'u-table-item-destac': props.row.isToday}, trClass(props.row)]">
        <u-td class="pos-rlt" auto-width>
          <u-checkbox color="primary" v-model="props.selected" size="xs"/>
        </u-td>
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="id" :props="props">
          {{ props.row.id }}
        </u-td>
        <u-td key="nome" :props="props">
          <router-link :to="{name: 'clientes.cliente', params: {id: props.row.id}}" tag="a">{{ props.row.pessoa.name }}</router-link>
        </u-td>
        <u-td key="domain" :props="props">
          {{ props.row.dominio }}
        </u-td>
        <u-td key="obs" :props="props" style="max-width: 200px;">
          <div style="word-wrap: break-word; overflow-wrap: break-word; white-space: normal; max-width: 200px; min-width: 100px; display: block">{{ props.row.observacao }}</div>
        </u-td>
        <u-td key="rate" :props="props">
          <satisfacao-rating :cliente="props.row" size="xs" />
        </u-td>
        <u-td key="plano" :props="props">
          {{ props.row.plano.nome }}
        </u-td>
        <u-td key="ticketIn" :props="props">
          {{ props.row.ticketIn|moeda }}
        </u-td>
        <u-td key="ticketOut" :props="props">
          {{ props.row.ticketOut|moeda }}
        </u-td>
        <u-td key="bens" :props="props">
          {{ props.row.statBens|number }}
        </u-td>
        <u-td key="leiloes" :props="props">
          {{ props.row.statLeiloes|number }}
        </u-td>
        <u-td key="vendas" :props="props">
          {{ props.row.statVendas|moeda }}
        </u-td>
        <u-td key="ultiomoPagamento" :props="props">
          {{ props.row.mensalidadeUltimoPagamento|formatDate('dd/MM/yyyy') }}
        </u-td>
        <u-td key="proximoVencimento" :props="props">
          {{ props.row.mensalidadeProximoVencimento|formatDate('dd/MM/yyyy') }}
        </u-td>
        <u-td key="mensalidade" :props="props">
          {{ props.row.mensalidade|moeda }}
        </u-td>
        <u-td class="text-uppercase" key="status" :props="props">
          <div :class="'cliente-status-' + props.row.status">
            <status-cliente :cliente="props.row" />
          </div>
        </u-td>
        <u-td class="td-limit today" key="dataPrazo" :props="props">
          <div v-if="props.row.deadline">
            {{ props.row.deadline|formatDate('dd/MM/yyyy HH:mm') }}
            <span class="m-l-xs trask-atraso-lbl" v-if="isOpen(props.row) && atraso(props.row.deadline) > 0"><i
                class="fas fa-exclamation-triangle m-r-xs"></i> {{ atraso(props.row.deadline) }} dia{{ atraso(props.row.deadline) > 1 ? 's' : '' }}</span>
          </div>
          <span v-else>Sem previsão</span>
        </u-td>
        <!--        <u-td class="text-center" key="options" :props="props">
                </u-td>-->
      </u-tr>
    </u-table>
  </div>
</template>

<script>
import {listName, listStorage} from "./config/list"
import listOptions from "@/reuse/list/listOptions"
import {LocalStorage, UCheckbox, UTable, UTd, UTh, UTr, UProgress, UTooltip} from "uloc-vue"
import {differenceInHours, parseISO} from "date-fns"
import StatusCliente from "components/clientes/components/cliente/include/StatusCliente"
import SatisfacaoRating from "components/clientes/components/cliente/include/SatisfacaoRating";

export default {
  name: "ClienteList",
  props: {
    data: {
      type: Array
    },
    options: {
      type: Object
    },
    request: {
      type: Function
    },
    optionColumn: {
      type: Boolean,
      default: true
    },
    colorize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const columns = this.listStorage.map(o => {
      if (this.project && o.name === 'projeto') {
        // Desativa coluna de projeto
        o.active = false
      }
      return {
        ...o,
        field: o.name,
        align: 'left',
      }
    })
    this.optionColumn && columns.concat([
      {
        name: 'options',
        required: true,
        label: '',
        field: 'options',
        sortable: false
      }
    ])
    return {
      listName: listName,
      listStorage: this.listStorage,
      columns: columns
    }
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      console.log('Ok', newConfig)
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    isOpen (cliente) {
      return cliente.status.type === 'opened'
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    abrir(t) {
      if (t.project) {
        this.$router.push({
          name: 'suporte.projeto.tarefa',
          params: {
            id: t.project.id,
            tarefa: t.id
          }
        })
      } else {
        this.$router.push({
          name: 'suporte.tarefa',
          params: {
            tarefa: t.id
          }
        })
      }
    },
    trClass (cliente) {
      const css = []
      if (this.colorize) {
        if (cliente.deadline && this.atraso(cliente.deadline) > 0) {
          css.push('sla-out')
        }
      }
      return css
    }
  },
  components: {
    SatisfacaoRating,
    StatusCliente,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    // UTooltip
  }
}
</script>
