import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 4
export const listName = 'sl.clientes.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 2},
    {label: 'Domínio', name: 'domain', active: true, sortable: true, ordering: 3},
    {label: 'Obs', name: 'obs', active: true, sortable: true, ordering: 5},
    {label: 'Rate', name: 'rate', active: true, sortable: true, ordering: 5},
    {label: 'Plano', name: 'plano', active: true, sortable: true, ordering: 5},
    {label: 'Ticket In', name: 'ticketIn', active: true, sortable: true, ordering: 5},
    {label: 'Ticket Out', name: 'ticketOut', active: true, sortable: true, ordering: 5},
    {label: 'Bens', name: 'bens', active: true, sortable: true, ordering: 5},
    {label: 'Leilões', name: 'leiloes', active: true, sortable: true, ordering: 5},
    {label: 'Vendas', name: 'vendas', active: true, sortable: true, ordering: 5},
    {label: 'Último pagamento', name: 'ultiomoPagamento', active: true, sortable: true, ordering: 5},
    {label: 'Próximo vencimento', name: 'proximoVencimento', active: true, sortable: true, ordering: 5},
    {label: 'Mensalidade', name: 'mensalidade', active: false, sortable: true, ordering: 5},
    {label: 'Status', name: 'status', active: true, sortable: true, ordering: 12},
], listVersion)
