<template>
  <div :class="statusClass">{{status ? status.label : '-'}}</div>
</template>

<script>

import StatusCliente from "components/gerencia/cliente/helpers/StatusCliente"

export default {
  name: 'StatusCliente',
  props: ['cliente'],
  data () {
    return {
    }
  },
  computed: {
    statusClass () {
      let css = ['cliente-status']
      if (this.status) {
        css.push(this.status.class)
      }
      return css
    },
    status () {
      return StatusCliente.find((s) => s.value === this.cliente.status)
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus">
  .cliente-status{
    &.text-positive{
      color #009505 !important
    }
    &.text-warning{
      color #956300 !important
    }
    &.text-negative{
      color #EF0505 !important
    }
  }
</style>
